// project import
import dashboard from './dashboard';
// import groups from './groups';
import tags from './tags';
// import meetings from './meetings';
import settings from './settings';

import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [
    dashboard,
    // meetings,
    // groups,
    tags,
    settings
  ]
};

export default menuItems;
