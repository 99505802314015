// types
import { OverbookdProps } from 'types/Overbookd';
import { createSlice } from '@reduxjs/toolkit';
import { EmptyAccount } from 'types/Account';

// initial state
const initialState: OverbookdProps = {
  Account: EmptyAccount(),
  Users: undefined,
  Groups: undefined,
  Tags: undefined,
  Meetings: undefined,
  Roles: undefined
};

// ==============================|| SLICE - MENU ||============================== //

const overbookd = createSlice({
  name: 'overbookd',
  initialState,
  reducers: {
    updateAccount: (state, action) => {
      console.log('update account', state, action);
      state.Account = action.payload;
    },
    updateGroups: () => {
      console.log('update groups');
    },
    updateMeetings: () => {
      console.log('update meetings');
    },
    updateRoles: () => {
      console.log('update roles');
    },
    updateTags: () => {
      console.log('update tags');
    },
    updateUsers: () => {
      console.log('update users');
    }
  }
});

export default overbookd.reducer;

export const { updateAccount, updateGroups, updateMeetings, updateRoles, updateTags, updateUsers } = overbookd.actions;
