// core
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// components
import MainLayout from 'layout/MainLayout';
import LimitedAccessLayout from 'layout/MainLayout/limitedaccess';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// ****************************************************************************************************************
// public routes
// ****************************************************************************************************************

// maintenance routes
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// ****************************************************************************************************************
// authenticated routes
// ****************************************************************************************************************

// meetings
const OnboardHandler = Loadable(lazy(() => import('pages/onboard/Onboarding')));
const VerifyEmailHandler = Loadable(lazy(() => import('pages/onboard/Verify')));

// dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboards/default')));

// meetings
const MeetingsList = Loadable(lazy(() => import('pages/meeting/list')));
const MeetingDetails = Loadable(lazy(() => import('pages/meeting/details')));
const CalendarConnection = Loadable(lazy(() => import('pages/settings/CalendarConnection')));
const CalendarConnectionCallback = Loadable(lazy(() => import('pages/settings/CalendarConnectionCallback')));

// groups
const GroupsList = Loadable(lazy(() => import('pages/group/GroupsList')));

// tags
const TagsList = Loadable(lazy(() => import('pages/tags/TagsList')));

// user
const UsersList = Loadable(lazy(() => import('pages/users/UsersList')));

// account
const AccountDetails = Loadable(lazy(() => import('pages/account/AccountDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <LimitedAccessLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'onboard',
          element: <OnboardHandler />
        },
        {
          path: 'onboard/verify',
          element: <VerifyEmailHandler />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'meetings',
          element: <MeetingsList />
        },
        {
          path: 'meetings/connect',
          element: <CalendarConnection />
        },
        {
          path: 'meetings/:id/details',
          element: <MeetingDetails />
        },
        {
          path: 'groups',
          element: <GroupsList />
        },
        {
          path: 'tags',
          element: <TagsList />
        },
        {
          path: 'settings',
          element: <Outlet />,
          children: [
            {
              path: 'connection',
              element: <CalendarConnection />
            },
            {
              path: 'connection/callback',
              element: <CalendarConnectionCallback />
            },
            {
              path: 'users',
              element: <UsersList />
            },
            {
              path: 'account',
              element: <AccountDetails />
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
