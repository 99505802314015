// action - state management
import { UPDATE, REGISTER, LOGIN, LOGOUT, REFRESH } from './actions';

// types
import { AuthProps, AuthActionProps } from 'types/Auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case UPDATE: {
      const { user } = action.payload!;

      return {
        ...state,
        user
      };
    }
    case REGISTER: {
      const { user, token } = action.payload!;

      localStorage.setItem('obtoken', token);

      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user, needsOnboarding, needsEmailVerification, token } = action.payload!;

      localStorage.setItem('obtoken', token);

      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        needsEmailVerification: needsEmailVerification,
        needsOnboarding: needsOnboarding,
        user
      };
    }
    case LOGOUT: {
      localStorage.removeItem('obtoken');

      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        needsEmailVerification: false,
        needsOnboarding: false,
        user: null
      };
    }
    case REFRESH: {
      const { user } = action.payload!;

      return {
        ...state,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
