import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/Auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, needsOnboarding, needsEmailVerification } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('authguard', isLoggedIn);

    if (!isLoggedIn) {
      navigate('login', { replace: true });
      return;
    }

    if (needsEmailVerification) {
      navigate('/onboard/verify', { replace: true });
      return;
    }

    if (needsOnboarding) {
      navigate('/onboard', { replace: true });
      return;
    }
  }, [isLoggedIn, navigate, needsOnboarding, needsEmailVerification]);

  return children;
};

export default AuthGuard;
