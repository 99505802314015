const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    <>
      <svg width="198" height="52" viewBox="0 0 198 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7386 39.6899C19.4155 41.3668 22.6048 41.1564 24.628 39.594L18.8089 45.4131C16.3128 47.9092 12.275 47.9851 9.71593 45.5925L9.6835 45.5573L4.71514 40.5917C-0.44196 35.4346 -0.441947 27.0733 4.71515 21.9162L6.79736 19.834L6.81965 19.8563C4.42705 22.4153 4.47879 26.4301 6.97486 28.9262L17.7386 39.6899Z"
          fill="url(#paint0_linear_603_28)"
        />
        <path
          d="M12.7013 27.8572C11.0243 29.5341 11.2347 32.7234 12.7971 34.7466L6.97802 28.9276C4.48195 26.4315 4.40603 22.3936 6.79862 19.8346L6.83377 19.8022L11.7994 14.8338C16.9565 9.67669 25.3178 9.6767 30.4749 14.8338L32.5571 16.916L32.5348 16.9383C29.9758 14.5457 25.961 14.5974 23.4649 17.0935L12.7013 27.8572Z"
          fill="url(#paint1_linear_603_28)"
        />
        <path
          d="M29.5727 34.6369C31.2496 32.96 31.0393 29.7707 29.4768 27.7475L35.2959 33.5666C37.792 36.0626 37.8679 40.1005 35.4753 42.6596L35.4402 42.692L30.4745 47.6603C25.3174 52.8174 16.9561 52.8174 11.799 47.6603L9.7168 45.5781L9.73908 45.5558C12.2981 47.9484 16.3129 47.8967 18.809 45.4006L29.5727 34.6369Z"
          fill="url(#paint2_linear_603_28)"
        />
        <path
          d="M24.5261 22.8062C22.8492 21.1293 19.6599 21.3397 17.6367 22.9021L23.4557 17.083C25.9518 14.5869 29.9897 14.511 32.5487 16.9036L32.5812 16.9387L37.5495 21.9044C42.7066 27.0615 42.7066 35.4228 37.5495 40.5799L35.4673 42.6621L35.445 42.6398C37.8376 40.0808 37.7859 36.066 35.2898 33.5699L24.5261 22.8062Z"
          fill="url(#paint3_linear_603_28)"
        />
        <path
          d="M48.0708 31.9125C48.0708 30.7268 48.2525 29.635 48.6159 28.6371C48.9891 27.6293 49.5047 26.7944 50.1628 26.1324C51.4592 24.8281 53.1829 24.176 55.3338 24.176C57.5043 24.176 59.2525 24.8331 60.5784 26.1472C61.9141 27.4514 62.582 29.3089 62.582 31.7198C62.582 34.1208 61.9681 36.0672 60.7405 37.5592C59.5128 39.0511 57.7105 39.7971 55.3338 39.7971C52.957 39.7971 51.1498 39.0758 49.9123 37.6333C48.6846 36.1808 48.0708 34.2739 48.0708 31.9125ZM55.3338 38.0186C56.473 38.0186 57.3963 37.495 58.1034 36.4476C58.8204 35.4003 59.1788 33.9281 59.1788 32.031C59.1788 28.9779 58.4128 27.071 56.8806 26.3102C56.4092 26.0731 55.8936 25.9545 55.3338 25.9545C53.5168 25.9545 52.3333 27.0611 51.7833 29.2744C51.5869 30.0352 51.4887 30.9244 51.4887 31.9421C51.4887 33.8984 51.8422 35.4003 52.5494 36.4476C53.2663 37.495 54.1945 38.0186 55.3338 38.0186Z"
          fill="#F2388F"
        />
        <path
          d="M65.2927 27.6885C64.9981 27.0463 64.7329 26.6214 64.4972 26.414C64.2713 26.2065 63.7851 26.1027 63.0387 26.1027V24.4724H70.9204V26.1027C69.8302 26.1027 69.1623 26.2163 68.9168 26.4436C68.6713 26.661 68.6713 27.0759 68.9168 27.6885L72.2463 35.8103L75.7967 27.6885C76.0423 27.1253 75.9932 26.7202 75.6494 26.4732C75.3155 26.2262 74.574 26.1027 73.4249 26.1027V24.4724H80.1427V26.1027C79.524 26.1027 79.0525 26.2312 78.7284 26.4881C78.4043 26.7351 78.1195 27.1352 77.874 27.6885L72.4673 39.5007H70.8615L65.2927 27.6885Z"
          fill="#F2388F"
        />
        <path
          d="M94.6392 32.6683H84.415C84.415 34.3678 84.8128 35.6819 85.6083 36.6106C86.4137 37.5394 87.4057 38.0038 88.5842 38.0038C90.1655 38.0038 91.2999 37.3467 91.9874 36.0326L94.1825 36.0178C93.9566 37.075 93.3084 37.9692 92.2378 38.7004C91.1771 39.4315 89.7432 39.7971 87.936 39.7971C86.1289 39.7971 84.5673 39.2339 83.2512 38.1075C81.6798 36.7539 80.8941 34.847 80.8941 32.3867C80.8941 29.9265 81.5177 27.9454 82.765 26.4436C84.0222 24.9319 85.8195 24.176 88.157 24.176C91.2311 24.176 93.22 25.421 94.1235 27.9109C94.4869 28.9088 94.6686 29.8524 94.6686 30.7416C94.6686 31.6309 94.6588 32.2731 94.6392 32.6683ZM91.236 30.4452C91.236 27.4218 90.2146 25.91 88.1717 25.91C87.0226 25.91 86.124 26.3596 85.4758 27.2587C84.8374 28.1579 84.4887 29.4028 84.4298 30.9936H91.2213C91.2311 30.8058 91.236 30.6231 91.236 30.4452Z"
          fill="#F2388F"
        />
        <path
          d="M97.2173 37.8704C98.0619 37.8704 98.5923 37.7864 98.8084 37.6185C99.0244 37.4406 99.1521 37.2529 99.1914 37.0553C99.2405 36.8577 99.2651 36.6106 99.2651 36.3142V28.148C99.2651 27.4366 99.2012 26.9722 99.0735 26.7548C98.9557 26.5375 98.6267 26.4288 98.0865 26.4288H97.1878V24.7985C98.1209 24.7985 98.8427 24.7293 99.3534 24.591C99.874 24.4428 100.242 24.339 100.458 24.2798C100.684 24.2106 100.925 24.176 101.18 24.176C101.681 24.176 102.015 24.3242 102.182 24.6206C102.359 24.9171 102.447 25.4012 102.447 26.0731V26.3695C103.292 25.3716 104.249 24.6997 105.32 24.3539C105.703 24.2353 106.086 24.176 106.469 24.176C107.206 24.176 107.775 24.3884 108.178 24.8133C108.591 25.2382 108.797 25.7618 108.797 26.3843C108.797 27.0068 108.6 27.5156 108.207 27.9109C107.815 28.3061 107.338 28.5037 106.778 28.5037C106.228 28.5037 105.772 28.3061 105.408 27.9109C105.055 27.5156 104.898 27.0216 104.937 26.4288C104.534 26.4881 104.102 26.6758 103.641 26.992C103.189 27.2983 102.791 27.7182 102.447 28.2517V36.1808C102.447 36.5563 102.477 36.8577 102.536 37.0849C102.604 37.3023 102.776 37.49 103.051 37.6481C103.336 37.7963 104.181 37.8704 105.585 37.8704V39.5007H97.2173V37.8704Z"
          fill="#F2388F"
        />
        <path
          d="M114.852 37.7667C114.429 38.1718 114.218 38.7498 114.218 39.5007H112.038V20.3671C112.038 19.6557 111.974 19.1913 111.846 18.9739C111.728 18.7566 111.395 18.6479 110.845 18.6479H109.799V17.0176C110.751 17.0176 111.498 16.9484 112.038 16.8101C112.588 16.6619 112.976 16.5581 113.202 16.4989C113.437 16.4297 113.688 16.3951 113.953 16.3951C114.474 16.3951 114.812 16.5483 114.97 16.8545C115.137 17.151 115.22 17.6302 115.22 18.2922V26.2361C116.271 24.8627 117.774 24.176 119.728 24.176C121.692 24.176 123.249 24.8084 124.398 26.0731C125.557 27.3279 126.137 29.1805 126.137 31.6309C126.137 34.0812 125.533 36.0573 124.324 37.5592C123.126 39.0511 121.447 39.7971 119.286 39.7971C117.508 39.7971 116.03 39.1203 114.852 37.7667ZM115.146 32.1644C115.146 33.9725 115.485 35.4003 116.163 36.4476C116.85 37.495 117.778 38.0186 118.947 38.0186C120.106 38.0186 121.02 37.4801 121.687 36.4032C122.365 35.3262 122.704 33.8984 122.704 32.12C122.704 29.2842 122.036 27.3971 120.7 26.4584C120.219 26.1324 119.649 25.9693 118.991 25.9693C118.343 25.9693 117.783 26.1274 117.312 26.4436C116.841 26.7598 116.448 27.1945 116.133 27.7478C115.475 28.8841 115.146 30.3563 115.146 32.1644Z"
          fill="#F2388F"
        />
        <path
          d="M128.744 31.9125C128.744 30.7268 128.926 29.635 129.289 28.6371C129.662 27.6293 130.178 26.7944 130.836 26.1324C132.133 24.8281 133.856 24.176 136.007 24.176C138.178 24.176 139.926 24.8331 141.252 26.1472C142.587 27.4514 143.255 29.3089 143.255 31.7198C143.255 34.1208 142.641 36.0672 141.414 37.5592C140.186 39.0511 138.384 39.7971 136.007 39.7971C133.63 39.7971 131.823 39.0758 130.586 37.6333C129.358 36.1808 128.744 34.2739 128.744 31.9125ZM136.007 38.0186C137.146 38.0186 138.07 37.495 138.777 36.4476C139.494 35.4003 139.852 33.9281 139.852 32.031C139.852 28.9779 139.086 27.071 137.554 26.3102C137.083 26.0731 136.567 25.9545 136.007 25.9545C134.19 25.9545 133.007 27.0611 132.457 29.2744C132.26 30.0352 132.162 30.9244 132.162 31.9421C132.162 33.8984 132.516 35.4003 133.223 36.4476C133.94 37.495 134.868 38.0186 136.007 38.0186Z"
          fill="#F2388F"
        />
        <path
          d="M145.922 31.9125C145.922 30.7268 146.104 29.635 146.467 28.6371C146.84 27.6293 147.356 26.7944 148.014 26.1324C149.31 24.8281 151.034 24.176 153.185 24.176C155.355 24.176 157.104 24.8331 158.429 26.1472C159.765 27.4514 160.433 29.3089 160.433 31.7198C160.433 34.1208 159.819 36.0672 158.591 37.5592C157.364 39.0511 155.562 39.7971 153.185 39.7971C150.808 39.7971 149.001 39.0758 147.763 37.6333C146.536 36.1808 145.922 34.2739 145.922 31.9125ZM153.185 38.0186C154.324 38.0186 155.247 37.495 155.954 36.4476C156.671 35.4003 157.03 33.9281 157.03 32.031C157.03 28.9779 156.264 27.071 154.732 26.3102C154.26 26.0731 153.745 25.9545 153.185 25.9545C151.368 25.9545 150.184 27.0611 149.634 29.2744C149.438 30.0352 149.34 30.9244 149.34 31.9421C149.34 33.8984 149.693 35.4003 150.4 36.4476C151.117 37.495 152.045 38.0186 153.185 38.0186Z"
          fill="#F2388F"
        />
        <path
          d="M177.714 37.5592C178.391 37.5592 178.73 36.833 178.73 35.3805H180.159C180.228 35.6967 180.262 35.9783 180.262 36.2253C180.262 36.4624 180.258 36.7292 180.248 37.0256C180.238 37.322 180.13 37.7024 179.924 38.1668C179.413 39.2537 178.446 39.7971 177.021 39.7971C175.607 39.7971 174.468 38.982 173.604 37.3517C173.358 36.8873 173.117 36.418 172.882 35.9437C172.243 34.6395 171.713 33.7502 171.291 33.276C170.564 32.4757 169.685 32.0755 168.654 32.0755C168.575 32.0755 168.496 32.0755 168.418 32.0755C168.339 32.0755 168.261 32.0804 168.182 32.0903V36.2846C168.182 36.8972 168.31 37.3171 168.565 37.5444C168.83 37.7617 169.434 37.8704 170.377 37.8704V39.5007H162.761V37.8704C163.615 37.8704 164.2 37.7518 164.514 37.5147C164.838 37.2776 165 36.8675 165 36.2846V20.2337C165 19.5223 164.936 19.0579 164.808 18.8405C164.691 18.6232 164.357 18.5145 163.807 18.5145H162.613V16.8842C163.605 16.8842 164.381 16.815 164.941 16.6767C165.501 16.5285 165.899 16.4247 166.134 16.3655C166.38 16.2963 166.635 16.2617 166.9 16.2617C167.411 16.2617 167.75 16.4099 167.917 16.7063C168.094 17.0028 168.182 17.4869 168.182 18.1588V30.4748H168.933C170.554 29.3287 171.968 28.0986 173.176 26.7845C173.373 26.577 173.52 26.3991 173.618 26.2509H171.07V24.4724H179.202V26.2509C177.375 26.3003 175.504 27.1797 173.589 28.889C173.009 29.3929 172.43 29.9314 171.85 30.5045C173.461 31.1171 174.767 32.4855 175.769 34.6098C176.015 35.1434 176.241 35.6325 176.447 36.0771C176.653 36.5118 176.854 36.8675 177.051 37.1442C177.247 37.4209 177.468 37.5592 177.714 37.5592Z"
          fill="#F2388F"
        />
        <path
          d="M193.079 37.159C191.911 38.9177 190.32 39.7971 188.306 39.7971C186.293 39.7971 184.692 39.1302 183.504 37.7963C182.325 36.4624 181.736 34.6691 181.736 32.4164C181.736 29.6498 182.34 27.5848 183.548 26.2213C184.766 24.8578 186.43 24.176 188.542 24.176C190.143 24.176 191.513 24.7293 192.652 25.8359V20.3671C192.652 19.6656 192.579 19.2061 192.431 18.9887C192.294 18.7615 191.95 18.6479 191.4 18.6479H190.354V17.0176C191.326 17.0176 192.088 16.9484 192.638 16.8101C193.188 16.6619 193.58 16.5581 193.816 16.4989C194.052 16.4297 194.302 16.3951 194.567 16.3951C195.088 16.3951 195.427 16.5483 195.584 16.8545C195.751 17.151 195.834 17.6302 195.834 18.2922V35.5584C195.834 36.5168 195.874 37.1096 195.952 37.3369C196.119 37.7716 196.522 37.989 197.16 37.989C197.484 37.989 197.764 37.9346 198 37.826V39.2636C197.529 39.6193 196.89 39.7971 196.085 39.7971C194.474 39.7971 193.472 38.9177 193.079 37.159ZM187.142 37.5147C187.614 37.8507 188.183 38.0186 188.851 38.0186C190.148 38.0186 191.115 37.4752 191.754 36.3883C192.402 35.2916 192.726 33.7799 192.726 31.8532C192.726 28.9878 191.975 27.1501 190.472 26.3398C189.991 26.083 189.46 25.9545 188.881 25.9545C187.722 25.9545 186.813 26.493 186.155 27.57C185.497 28.6371 185.168 30.0648 185.168 31.8532C185.168 34.7086 185.826 36.5958 187.142 37.5147Z"
          fill="#F2388F"
        />
        <defs>
          <linearGradient id="paint0_linear_603_28" x1="18.1207" y1="46.1192" x2="6.47537" y2="34.4739" gradientUnits="userSpaceOnUse">
            <stop stopColor="#474360" />
            <stop offset="1" stopColor="#F2388F" />
          </linearGradient>
          <linearGradient id="paint1_linear_603_28" x1="19.2052" y1="15.1987" x2="6.31481" y2="28.2823" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F2388F" />
            <stop offset="1" stopColor="#474360" />
          </linearGradient>
          <linearGradient id="paint2_linear_603_28" x1="26.3745" y1="46.1757" x2="36.9358" y2="35.2065" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F2388F" />
            <stop offset="1" stopColor="#474360" />
          </linearGradient>
          <linearGradient id="paint3_linear_603_28" x1="22.7898" y1="16.1175" x2="35.3045" y2="28.6322" gradientUnits="userSpaceOnUse">
            <stop stopColor="#474360" />
            <stop offset="1" stopColor="#F2388F" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default LogoMain;
