// assets
import { Settings, Event, Group, AdminPanelSettings } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SETTINGS ||============================== //

const settings: NavItemType = {
  id: 'group-settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: Settings,
      url: '/settings',
      breadcrumbs: true,
      children: [
        {
          id: 'account',
          title: 'Account',
          type: 'item',
          url: '/settings/account',
          icon: AdminPanelSettings,
          breadcrumbs: false
        },
        {
          id: 'connection',
          title: 'Calendars',
          type: 'item',
          url: '/settings/connection',
          icon: Event,
          breadcrumbs: false
        },
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          url: '/settings/users',
          icon: Group,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default settings;
