import axios from 'axios';
import { Domain, GetToken } from 'services/config';
import { UserData } from 'types/User';

export const GetAllUsers = async () => {
  const path: string = `/api/users`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.get(url, config);

  return response.data.data;
};

export const GetMe = async () => {
  const path: string = `/api/me`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.get(url, config);
  return response.data.data as UserData;
};

export const GetUser = async (id: string) => {
  const path: string = `/api/user/${id}`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.get(url, config);
  return response.data.data as UserData;
};

export const RegisterUser = async (record: UserData) => {
  const path = `/api/register`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.post(url, record, config);
  return response.data.data;
};

export const InviteUser = async (record: UserData) => {
  const path = `/api/user/invite`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.post(url, record, config);
  return response.data.data;
};

export const SaveUser = async (record: UserData) => {
  const path = `/api/user/${record.id}`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.post(url, record, config);
  return response.data.data;
};

export const DeleteUser = async (id: string) => {
  const path: string = `/api/user/${id}`;
  const url: string = `${Domain}${path}`;

  const config = {
    headers: {
      Authorization: GetToken()
    }
  };

  const response = await axios.delete(url, config);
  return response.data.data as UserData;
};
