// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LicenseInfo } from '@mui/x-license-pro';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);
  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GCLOUD_CLIENT_ID}>
              <>
                <Routes />
                <Snackbar />
              </>
            </GoogleOAuthProvider>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
