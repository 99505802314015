// material-ui
import { Box, Button, Link, LinearProgress, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project import
import MainCard from 'components/MainCard';

// assets
import AnimateButton from 'components/@extended/AnimateButton';
import creditsSVG from 'assets/images/general/credits.svg';

// data
import { RootStateProps } from 'types/Root';
import useAuth from 'hooks/useAuth';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavCard = () => {
  const { user } = useAuth();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  return (
    <>
      {drawerOpen && user && user.account && (
        <MainCard sx={{ bgcolor: 'grey.50', m: 2.5 }}>
          <Stack alignItems="center" spacing={2.5}>
            <img width={150} src={creditsSVG} alt="credit summary graphic with ookies and piggy bank" />
            <Stack alignItems="center">
              <Typography variant="h5" color="white" sx={{ pt: 1, pb: 1, zIndex: 1 }}>
                {user.account.billing.creditsAvailable} Credits Remaining
              </Typography>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={user.account.billing.creditsQuota} />
              </Box>
            </Stack>
            <Box>
              <Box sx={{ mt: 1 }}>
                <AnimateButton>
                  <Button variant="outlined" size="small" component={Link} href="/settings/account">
                    Manage Credits
                  </Button>
                </AnimateButton>
              </Box>
              <Box display="flex" justifyContent="center" sx={{ mt: 0.5 }}>
                <Button variant="text" color="secondary" size="small" component={Link} href="/settings/connection">
                  Connect Calendars
                </Button>
              </Box>
            </Box>
          </Stack>
        </MainCard>
      )}
    </>
  );
};

export default NavCard;
