// assets
import { LocalOffer } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  LocalOffer
};

// ==============================|| MENU ITEMS - TAGS ||============================== //

const tags: NavItemType = {
  id: 'group-tags',
  type: 'group',
  children: [
    {
      id: 'tags',
      title: 'Tags',
      type: 'item',
      icon: icons.LocalOffer,
      url: '/tags',
      breadcrumbs: true
    }
  ]
};

export default tags;
