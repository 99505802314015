const LogoIcon = () => {
  return (
    <svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_703_13)">
        <path
          d="M54.6201 89.0847C59.5038 93.9683 68.7917 93.3557 74.6838 88.8056L57.7373 105.752C50.4681 113.021 38.7089 113.242 31.2564 106.275L31.1619 106.172L16.6929 91.7111C1.67425 76.6924 1.67428 52.3423 16.6929 37.3237L22.7568 31.2598L22.8217 31.3247C15.8539 38.7772 16.0046 50.4692 23.2738 57.7383L54.6201 89.0847Z"
          fill="url(#paint0_linear_703_13)"
        />
        <path
          d="M39.9485 54.6211C35.0649 59.5047 35.6775 68.7926 40.2276 74.6847L23.2811 57.7383C16.012 50.4691 15.7909 38.7099 22.7587 31.2574L22.861 31.1629L37.3221 16.6939C52.3408 1.67522 76.6909 1.67526 91.7096 16.6939L97.7734 22.7578L97.7085 22.8227C90.256 15.8549 78.564 16.0056 71.2949 23.2747L39.9485 54.6211Z"
          fill="url(#paint1_linear_703_13)"
        />
        <path
          d="M89.0837 74.3613C93.9673 69.4777 93.3547 60.1898 88.8046 54.2977L105.751 71.2441C113.02 78.5133 113.241 90.2725 106.274 97.7251L106.171 97.8195L91.7101 112.289C76.6914 127.307 52.3413 127.307 37.3227 112.288L31.2588 106.225L31.3237 106.16C38.7762 113.128 50.4682 112.977 57.7373 105.708L89.0837 74.3613Z"
          fill="url(#paint2_linear_703_13)"
        />
        <path
          d="M74.3867 39.9231C69.5031 35.0395 60.2152 35.6521 54.3231 40.2022L71.2695 23.2557C78.5387 15.9866 90.2979 15.7655 97.7504 22.7333L97.8449 22.8356L112.314 37.2967C127.333 52.3154 127.333 76.6655 112.314 91.6842L106.25 97.748L106.185 97.6832C113.153 90.2306 113.002 78.5387 105.733 71.2695L74.3867 39.9231Z"
          fill="url(#paint3_linear_703_13)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_703_13" x1="55.7331" y1="107.809" x2="21.8191" y2="73.8945" gradientUnits="userSpaceOnUse">
          <stop stopColor="#474360" />
          <stop offset="1" stopColor="#F2388F" />
        </linearGradient>
        <linearGradient id="paint1_linear_703_13" x1="58.8895" y1="17.7567" x2="21.3497" y2="55.8591" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2388F" />
          <stop offset="1" stopColor="#474360" />
        </linearGradient>
        <linearGradient id="paint2_linear_703_13" x1="79.7699" y1="107.965" x2="110.527" y2="76.02" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2388F" />
          <stop offset="1" stopColor="#474360" />
        </linearGradient>
        <linearGradient id="paint3_linear_703_13" x1="69.3302" y1="20.444" x2="105.776" y2="56.8898" gradientUnits="userSpaceOnUse">
          <stop stopColor="#474360" />
          <stop offset="1" stopColor="#F2388F" />
        </linearGradient>
        <clipPath id="clip0_703_13">
          <rect width="129" height="129" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
