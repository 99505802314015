import { ApiData } from './Api';

export const EmptyAccount = () => {
  return {
    id: '',
    label: '',
    owner: '',
    domain: '',
    status: '',
    created: '',
    updated: '',
    billing: {
      id: '',
      label: '',
      owner: '',
      cycle: '',
      plan: '',
      creditsMax: 0,
      creditsUsed: 0,
      creditsAvailable: 0,
      creditsQuota: 0,
      nextBillingDate: '',
      expiration: ''
    }
  } as AccountData;
};

export interface AccountBillingData {
  id: string;
  label: string;
  owner: string | null;
  cycle: string;
  plan: string;
  creditsMax: number;
  creditsUsed: number;
  creditsAvailable: number;
  creditsQuota: number;
  nextBillingDate: string;
  expiration: string;
}

export interface AccountPortalURL {
  url: string;
}

export interface AccountFinancialTitleData {
  id: string;
  label: string;
  upperbound: number;
  lowerbound: number;
  average: number;
}

export interface AccountFinancialsData {
  titles: { AccountFinancialTitleData };
  assignments: { string };
}

export interface AccountData extends ApiData {
  id: string;
  label: string;
  owner: string;
  domain: string;
  status?: string;
  financials?: AccountFinancialsData;
  billing: AccountBillingData;
}

export const NewAccount = () => {
  return {
    id: '',
    label: '',
    owner: '',
    domain: ''
  } as AccountData;
};
