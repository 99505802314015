import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import TagManager from 'react-gtm-module';

// action - state management
import { LOGIN, LOGOUT, REFRESH } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import { FIREBASE_API } from 'config';
import { AuthProps, FirebaseContextType } from 'types/Auth';

// data
import { UserData } from 'types/User';

// services
import { GetMe } from 'services/users';

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  needsOnboarding: false,
  needsEmailVerification: false,
  user: null,
  token: null
};

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          user.getIdToken().then((token) => {
            localStorage.setItem('obtoken', token);
            async function getCurrentUser() {
              const me = (await GetMe()) as UserData;
              me.fbuid = user.uid;
              me.token = token;

              console.log('got-user', me);

              const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
                dataLayer: {
                  environment: process.env.REACT_APP_ENV,
                  userId: me.id ? me.id : '',
                  accountId: me.account ? me.account.id : ''
                }
              };
              TagManager.initialize(tagManagerArgs);

              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  needsOnboarding: me.status.requireOnboarding,
                  needsEmailVerification: user.emailVerified ? false : true,
                  user: me,
                  token: token
                }
              });
            }

            getCurrentUser();
          });
        } else {
          dispatch({
            type: LOGOUT,
            payload: {
              isLoggedIn: false
            }
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseRegister = async (email: string, password: string) => {
    const response = firebase.auth().createUserWithEmailAndPassword(email, password);
    // .then((userCredential) => {
    //   // Email verification
    //   const user = userCredential.user;
    //   user.sendEmailVerification()
    //     .then(() => {
    //       // Verification email sent
    //       console.log('Verification email sent to ' + email);
    //     })
    //     .catch((error) => {
    //       console.error('Error sending verification email:', error);
    //     });
    // })
    // .catch((error) => {
    //   console.error('Error creating user:', error);
    // });

    return response;
  };

  const firebaseGoogleSignIn = () => {
    console.log('signing in with google');
    const provider = new firebase.auth.GoogleAuthProvider();
    const response = firebase.auth().signInWithPopup(provider);
    return response;
  };

  const logout = () => firebase.auth().signOut();

  const refresh = () => {
    GetMe().then((me: UserData) => {
      console.log('got me', me);
      const updatedAccountData = me.account;
      dispatch({
        type: REFRESH,
        payload: {
          ...state,
          user: {
            ...state.user,
            account: updatedAccountData
          }
        }
      });
    });
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
        refresh,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
