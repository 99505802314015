import { Outlet } from 'react-router-dom';

// material-ui
import { Button, Box, Toolbar } from '@mui/material';
import { Logout } from '@mui/icons-material';

// project import
import Footer from './Footer';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN LAYOUT ||============================== //

const LimitedAccessLayout = () => {
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleLogout} startIcon={<Logout />}>
            Logout
          </Button>
        </Toolbar>
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { sm: 2 } }}>
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column' }}>
            <Outlet />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LimitedAccessLayout;
