import { AxiosError } from 'axios';

export const Domain: string = process.env.REACT_APP_API_ENDPOINT;

export const Handle500 = (error: AxiosError) => {
  console.error(error.response);
  window.location.replace('/maintenance/500');
  return null;
};

export const Handle503 = (error: AxiosError) => {
  console.error(error.response);
  window.location.replace('/maintenance/500');
  return null;
};

export const Handle404 = (error: AxiosError) => {
  console.error(error.response);
  throw new Error('Boomshika');
};

export const GetToken = () => {
  return localStorage.getItem('obtoken');
};

export const HandleError = (error: AxiosError) => {
  switch (error.response.status) {
    case 404: {
      console.log('got 404');
      Handle404(error);
      break;
    }
    case 400: {
      console.log('got 400');
      Handle500(error);
      break;
    }
    case 401: {
      console.log('got 401');
      Handle500(error);
      break;
    }
    case 500: {
      console.log('got 500');
      Handle500(error);
      break;
    }
    case 503: {
      console.log('got 503');
      Handle503(error);
      break;
    }
    default: {
      console.log('unknown', error.response.status, error.response);
      Handle500(error);
      break;
    }
  }
};
