import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';

// assets
import { Person, Domain, Logout } from '@mui/icons-material';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleToggle: () => void;
}

const ProfileTab = ({ handleLogout, handleToggle }: Props) => {
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleAccountClick = () => {
    setSelectedIndex(2);
    navigate(`/settings/account`, { replace: true });
    handleToggle();
  };

  const handleUserClick = () => {
    setSelectedIndex(1);
    navigate(`/settings/users`, { replace: true });
    handleToggle();
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 1} onClick={handleUserClick}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={handleAccountClick}>
        <ListItemIcon>
          <Domain />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItemButton>

      {/* <Divider />

      <ListItemButton
        alignItems="flex-start"
        selected={selectedIndex === 3}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}
      >
        <ListItemIcon>
          <Savings />
        </ListItemIcon>
        <ListItemText
          primary="Credits"
          secondary={
            <>
              <Typography variant="body2" color="text.secondary">
                Fondue paneer danish fontina. Goat pecorino say cheese rubber cheese pecorino st.
              </Typography>
              <div>
                <Button variant="outlined" size="small">
                  Add Credits
                </Button>
              </div>
            </>
          }
        />
      </ListItemButton> */}

      <Divider />

      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
